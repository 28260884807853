import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getForm, isMember } from "../api";
import { Button, TextInput } from "./components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HtmlContent = styled.div`
  margin-bottom: 16px;
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  color: #b00000;
`;

const EmailInput = styled(TextInput)`
  width: 28em;
`;

const formData = {
  exposition: "",
  membershipRequest: "",
};

const EmailChecker = ({
  onContinue,
  email: emailProp,
}: {
  onContinue: (email: string) => void;
  email?: string;
}) => {
  // const from = React.useMemo(
  //   () => new URLSearchParams(window.location.search).get("from") || "",
  //   []
  // );
  const [email, setEmail] = useState(emailProp || "");
  const [error, setError] = useState("");

  const [formContent, setFormContent] = useState(formData.exposition);
  const [membershipContent, setMembershipContent] = useState(
    formData.membershipRequest
  );
  useEffect(() => {
    getForm("exposition").then(({ content }) => {
      formData.exposition = content;
      setFormContent(content);
    });
    getForm("membershipRequest").then(({ content }) => {
      formData.membershipRequest = content;
      setMembershipContent(content);
    });
  }, []);

  const html = useMemo(() => ({ __html: formContent }), [formContent]);

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setEmail(e.target.value?.toLowerCase().trim());
    },
    []
  );

  const handleContinue = async () => {
    try {
      const isValidMember = await isMember(email);
      if (isValidMember) onContinue(email);
      else setError(membershipContent);
    } catch (error) {
      console.error(error);
      setError(`Une erreur est survenue :\n${(error as Error).message}`);
    }
  };

  const errorHtml = React.useMemo(() => ({ __html: error }), [error]);

  return (
    <Container>
      <HtmlContent dangerouslySetInnerHTML={html} />
      <EmailInput
        type="email"
        value={email}
        onChange={handleChange}
        placeholder="Email"
      />
      <Button onClick={handleContinue}>Continuer</Button>
      {error && <ErrorMessage dangerouslySetInnerHTML={errorHtml} />}
    </Container>
  );
};

export default EmailChecker;
