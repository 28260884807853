import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  ExtendedBook,
  FormData,
  getBookStatusForUser,
  submitForm,
} from "../api";
import { Button, TextInput } from "./components";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
`;

const FieldWrapper = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const LivreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
`;

const LivreInput = styled(TextInput)`
  flex: 1 1 auto;
`;

const AddBookButton = styled(Button)`
  margin: 0;
`;

const RemoveBookButton = styled.button`
  border-radius: 0.5em;
  border: none;
  font-weight: bold;
  font-size: 1.2em;
  flex: 0 0 auto;
  cursor: pointer;
  color: white;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 1.5em;
  width: 1.5em;
  line-height: 1;
  margin-bottom: 1em;

  &:hover {
    background-color: darkred;
  }

  &:after {
    content: "×";
  }
`;

const ErrorMessage = styled.div`
  color: red;
`;

const InfoMessage = styled.div`
  color: blue;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
`;

export const colorsPerStatus: Record<
  Exclude<ExtendedBook["status"], undefined>,
  string
> = {
  outdated: "red",
  pending: "orange",
  available: "green",
  unavailable: "red",
  unknown: "orange",
  requested: "yellow",
  duplicate: "red",
};

const statusTranslations: Record<
  Exclude<ExtendedBook["status"], undefined>,
  string
> = {
  outdated:
    "Trop ancien. Ce livre n'est pas concerné par la loi d'accessibilité handicap",
  pending:
    "Une recherche est en cours pour ce livre. Nous ne pouvons pas garantir de vous l'obtenir",
  available: "Ce livre est disponible",
  unavailable: "Ce livre n'est pas disponible",
  requested:
    "La demande de cette référence a déjà été faite à la Bibliothèque Nationale de France (BNF) qui est l'organisme intermédiaire entre Bookin et les éditeurs. Il faut donc que les éditeurs les envoient à la BNF qui elle-même devra nous les envoyer pour que nous les adaptions... avant que vous les receviez. Par conséquent, nous ne pouvons promettre aucun délai. Mais sachez que votre demande reste enregistrée",
  unknown: `Ce livre n'est pas disponible et nous devons en faire la demande à la Bibliothèque Nationale de France qui elle-même devra peut-être le réclamer à l'éditeur. Nous ne pouvons malheureusement pas vous donner de délai quant à sa disponibilité.
Ne repassez pas de demande sur cette référence car elle est bien enregistrée.
Nous vous enverrons cet ouvrage dès qu'il sera disponible ou bien vous serez informé si nous ne pouvons pas l'obtenir.
Soyez assuré que nous faisons tout pour satisfaire au mieux votre demande.`,
  duplicate: `Vous avez déjà fait une demande pour ce livre.`,
};

const StatusMessage = styled.div<{
  status: Exclude<ExtendedBook["status"], undefined>;
}>`
  color: ${(props) => colorsPerStatus[props.status]};
`;

const RememberMe = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1em;
  align-self: center;
  cursor: pointer;
`;

export type RememberedFields = {
  email: string;
  receptionEmail?: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  zipCode: string;
  childLastname: string;
  childFirstname: string;
};

const REMEMBER_ME_KEY = "remember-me";
const FORM_DATA_KEY = "form-data";
const MAX_BOOKS = 15;
export const BOOK_PATTERN = "^(9782\\d{9}|97910\\d{8})$";

function FormBody({
  goBack,
  email,
  onContinue,
}: {
  goBack: () => void;
  email: string;
  onContinue: () => void;
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const clearErrorMessage = useCallback(() => setErrorMessage(""), []);

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        await submitForm(data);
        onContinue();
      } catch (error) {
        const message = error
          ? (error as Error).message
          : (error as object).toString();
        if (message.toLowerCase() === "conflict")
          setErrorMessage("Vous avez déjà une demande en cours pour ce livre");
        else setErrorMessage(message);
        console.error(error);
      }
    },
    [onContinue]
  );
  const [books, setBooks] = useState<ExtendedBook[]>([{ bookId: "" }]);
  const [formData, setFormData] = useState<Partial<RememberedFields>>(() => {
    const memory = localStorage.getItem(FORM_DATA_KEY);
    return memory ? { ...JSON.parse(memory), email } : { email };
  });
  const [rememberMe, setRememberMe] = useState(
    !!localStorage.getItem(REMEMBER_ME_KEY)
  );
  const lastBookRef = React.useRef<HTMLInputElement>(null);

  const ajouterLivre = useCallback(() => {
    setTimeout(() => lastBookRef.current?.focus(), 300);
    setBooks((books) =>
      books.length >= MAX_BOOKS ? books : [...books, { bookId: "" }]
    );
  }, []);

  const retirerLivre = useCallback(
    (index: number) => {
      setTimeout(() => lastBookRef.current?.focus(), 300);
      clearErrorMessage();
      setBooks((livres) => {
        if (livres.length < 2) return livres;
        const nouveauxLivres = [...livres];
        nouveauxLivres.splice(index, 1);
        return nouveauxLivres;
      });
    },
    [clearErrorMessage]
  );

  const updateBookStatus = useCallback(
    (bookId: string) => {
      getBookStatusForUser(bookId, email)
        .then(({ status, comment }) => {
          setBooks((books) => {
            const index = books.findIndex((book) => book.bookId === bookId);
            const newBooks = [...books];
            newBooks.splice(index, 1, { bookId, status, comment });
            return newBooks;
          });
        })
        .catch(console.error);
    },
    [email]
  );

  const handleLivreChange = useCallback(
    (index: number, value: string) => {
      clearErrorMessage();
      setBooks((livres) => {
        const bookId = value.replace(/\s/g, "");
        const nouveauxLivres = [...livres];
        nouveauxLivres[index] = { bookId };
        const regexp = new RegExp(BOOK_PATTERN);
        if (regexp.test(bookId)) updateBookStatus(bookId);
        return nouveauxLivres;
      });
    },
    [clearErrorMessage, updateBookStatus]
  );

  const handleRememberMeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRememberMe(event.target.checked);
      event.target.checked
        ? localStorage.setItem(REMEMBER_ME_KEY, "true")
        : localStorage.removeItem(REMEMBER_ME_KEY);
    },
    []
  );

  const handleFormData = useCallback(
    (name: string, removeSpaces?: boolean) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        clearErrorMessage();
        setFormData((data) => ({
          ...data,
          [name]: removeSpaces
            ? event.target.value.replace(/\s/g, "")
            : event.target.value,
        }));
      },
    [clearErrorMessage]
  );

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    async (event) => {
      clearErrorMessage();
      event.preventDefault();
      const formatedData = Object.fromEntries(
        Object.entries(formData).map(([key, value]) => [key, value.trim()])
      ) as RememberedFields;

      rememberMe
        ? localStorage.setItem(FORM_DATA_KEY, JSON.stringify(formatedData))
        : localStorage.removeItem(FORM_DATA_KEY);
      await onSubmit({
        ...formatedData,
        books: books.map((book) => book.bookId),
        childLastname: formatedData.childLastname || formatedData.lastname,
      });
    },
    [books, clearErrorMessage, formData, onSubmit, rememberMe]
  );

  const cannotSubmit = React.useMemo(
    () =>
      !books.length ||
      books.some((book) =>
        ["unavailable", "outdated"].includes(book.status as "available")
      ),
    [books]
  );

  const bookItems = React.useMemo(
    () =>
      books.map((book, index) => (
        <FieldWrapper key={index}>
          Code barre du livre (commence par 9782 ou 97910 et contient 13
          chiffres)*
          <LivreWrapper>
            <LivreInput
              type="text"
              name="code-barre"
              ref={index === books.length - 1 ? lastBookRef : undefined}
              pattern={BOOK_PATTERN}
              required
              value={book.bookId || ""}
              onChange={(e) => handleLivreChange(index, e.target.value)}
              onKeyDown={(event) =>
                event.key === "Enter" && event.preventDefault()
              }
            />
            {books.length > 1 ? (
              <RemoveBookButton
                type="button"
                title="Supprimer le livre"
                onClick={() => retirerLivre(index)}
                disabled={books.length === 1}
              />
            ) : undefined}
          </LivreWrapper>
          {book.status && (
            <StatusMessage status={book.status}>
              {statusTranslations[book.status]}
              {book.comment && (
                <>
                  <br />
                  Motif : {book.comment}
                </>
              )}
            </StatusMessage>
          )}
        </FieldWrapper>
      )),
    [books, handleLivreChange, retirerLivre]
  );

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FieldWrapper>
        Nom du responsable légal*
        <TextInput
          type="text"
          name="lastname"
          required
          value={formData.lastname || ""}
          onChange={(lastname) => {
            handleFormData("lastname")(lastname);
            handleFormData("childLastname")(lastname);
          }}
        />
      </FieldWrapper>
      <FieldWrapper>
        Prénom du responsable légal*
        <TextInput
          type="text"
          name="firstname"
          required
          value={formData.firstname || ""}
          onChange={handleFormData("firstname")}
        />
      </FieldWrapper>
      <FieldWrapper>
        Téléphone*
        <TextInput
          type="tel"
          name="phoneNumber"
          required
          pattern="^(\+33|0)\d{9}$"
          value={formData.phoneNumber || ""}
          onChange={handleFormData("phoneNumber", true)}
        />
      </FieldWrapper>
      <FieldWrapper>
        Code postal*
        <TextInput
          type="text"
          name="zip-code"
          required
          pattern="^\d{5}$"
          value={formData.zipCode || ""}
          onChange={handleFormData("zipCode", true)}
        />
      </FieldWrapper>
      <FieldWrapper>
        Nom de l'enfant*
        <TextInput
          type="text"
          name="child-lastname"
          required
          value={formData.childLastname || ""}
          onChange={handleFormData("childLastname")}
        />
      </FieldWrapper>
      <FieldWrapper>
        Prénom de l'enfant*
        <TextInput
          type="text"
          name="child-firstname"
          required
          value={formData.childFirstname || ""}
          onChange={handleFormData("childFirstname")}
        />
      </FieldWrapper>
      {bookItems}
      {books.length < MAX_BOOKS ? (
        <AddBookButton color="secondary" type="button" onClick={ajouterLivre}>
          + Ajouter un autre livre
        </AddBookButton>
      ) : undefined}
      <br />
      <FieldWrapper>
        <RememberMe>
          Se rappeler de moi
          <TextInput
            type="checkbox"
            checked={rememberMe || false}
            onChange={handleRememberMeChange}
          />
        </RememberMe>
      </FieldWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {bookItems.length > 1 && cannotSubmit && (
        <InfoMessage>
          Pour envoyer le formulaire, cliquez sur la croix rouge pour supprimer
          les références non disponibles.
        </InfoMessage>
      )}
      <ButtonContainer>
        <Button color="negative" onClick={goBack}>
          Précédent
        </Button>
        <Button type="submit" disabled={cannotSubmit}>
          Envoyer
        </Button>
      </ButtonContainer>
    </StyledForm>
  );
}

export default FormBody;
