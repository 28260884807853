import styled from "styled-components";

const getBackgroundColor = (
  color: "primary" | "secondary" | "negative" = "primary",
  hover?: boolean
) => {
  if (hover) {
    switch (color) {
      case "primary":
        return "#331756";
      case "negative":
        return "#331756";
      case "secondary":
        return "#AD1843";
    }
  } else {
    switch (color) {
      case "primary":
        return "#733c8f";
      case "negative":
        return "#F4F4F4";
      case "secondary":
        return "#E21B54";
    }
  }
};

const getColor = (
  color: "primary" | "secondary" | "negative" = "primary",
  hover?: boolean
) => {
  if (color !== "negative") return "#ffffff";
  else return hover ? "#ffffff" : "#2A044A";
};

export const Button = styled.button<{
  color?: "primary" | "secondary" | "negative";
}>`
  color: ${(props) => getColor(props.color, false)};
  padding: 0.6em 1.5em;
  border-radius: 1.5em;
  border: none;
  margin: 0.3em 0.5em;
  cursor: pointer;
  min-width: 10em;
  font-size: 1em;
  font-weight: 600;
  background-color: ${(props) => getBackgroundColor(props.color, false)};

  ${(props) =>
    props.disabled
      ? "opacity: 0.5;"
      : `
  &:hover {
    color: ${getColor(props.color, true)};
    background-color: ${getBackgroundColor(props.color, true)};
  }`}
`;

export const TextInput = styled.input`
  margin-bottom: 1em;
  padding: 0.5em 0.8em;
  border-radius: 1.2em;
  border: none;
  font-size: 1em;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
  &:focus {
    outline: 2px solid #733c8f;
  }
`;
