import { useState, useEffect, useMemo } from "react";
import { setJWTErrorHandler } from "../api";
import Dashboard from "./RequestsDashboard";
import AuthScreen from "./AuthScreen";
import Navigation, { Screen } from "./Navigation";
import EditEmails from "./EditEmails";
import EditForms from "./EditForms";
import ManageUsers from "./ManageUsers";
import ManageRequests from "./ManageRequests";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: stretch;
`;

function AdminPanel() {
  const [jwt, setJWT] = useState(localStorage.getItem("jwt") || "");
  useEffect(() => {
    // This is a hack to reset the jwt token on error
    setJWTErrorHandler(() => setJWT(""));
  }, []);
  if (!jwt) return <AuthScreen setJWT={setJWT} />;
  else return <PanelContent />;
}

export default AdminPanel;

function PanelContent() {
  const [screen, setScreen] = useState<Screen>("dashboard");
  const body = useMemo(() => {
    if (screen === "editMail") return <EditEmails />;
    else if (screen === "editForm") return <EditForms />;
    else if (screen === "manageUsers") return <ManageUsers />;
    else if (screen === "manageRequests") return <ManageRequests />;
    else return <Dashboard />;
  }, [screen]);

  return (
    <Container>
      <Navigation screen={screen} setScreen={setScreen} />
      {body}
    </Container>
  );
}
