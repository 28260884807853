import React, { useCallback } from "react";
import styled from "styled-components";

const NavBarContainer = styled.div`
  display: flex;
  padding: 16px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
`;

const Button = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? "#001840" : "#007bff")};
  border: none;
  border-radius: 4px;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 0 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.active ? "#003680" : "#0056b3")};
  }
`;

export type Screen =
  | "dashboard"
  | "editMail"
  | "editForm"
  | "manageUsers"
  | "manageRequests";

const Navbar = ({
  screen,
  setScreen,
}: {
  screen: Screen;
  setScreen: (screen: Screen) => void;
}) => {
  const toEditMails = useCallback(() => {
    setScreen("editMail");
  }, [setScreen]);

  const toEditForms = useCallback(() => {
    setScreen("editForm");
  }, [setScreen]);

  const toDashboard = useCallback(() => {
    setScreen("dashboard");
  }, [setScreen]);

  const toUserManagement = useCallback(() => {
    setScreen("manageUsers");
  }, [setScreen]);

  const toRequestsManagement = useCallback(() => {
    setScreen("manageRequests");
  }, [setScreen]);

  return (
    <NavBarContainer>
      <Button active={screen === "dashboard"} onClick={toDashboard}>
        Requêtes à traiter
      </Button>
      <Button active={screen === "editMail"} onClick={toEditMails}>
        Éditer emails
      </Button>
      <Button active={screen === "editForm"} onClick={toEditForms}>
        Éditer formulaire
      </Button>
      <Button active={screen === "manageUsers"} onClick={toUserManagement}>
        Gérer utilisateurs
      </Button>
      <Button
        active={screen === "manageRequests"}
        onClick={toRequestsManagement}
      >
        Gérer les requêtes
      </Button>
    </NavBarContainer>
  );
};

export default Navbar;
