import RequestForm from "./RequestForm";
import { isElectron } from "./api";
import Dashboard from "./Dashboard";

function App() {
  const isDashboard =
    isElectron() || location.pathname.startsWith("/dashboard");
  if (isDashboard) return <Dashboard />;
  else return <RequestForm />;
}

export default App;
