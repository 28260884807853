import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { EmailId, getEmail, setEmail } from "../api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 32px;
  background-color: #f1f1f1;
`;

const Dropdown = styled.select`
  margin-bottom: 16px;
  padding: 8px;
  align-self: flex-start;
  background-color: white;
`;

const TextInput = styled.input`
  margin-bottom: 16px;
  padding: 8px;
`;

const Preview = styled.div`
  margin-bottom: 16px;
  padding: 8px;
  border: 2px solid blue;
`;

const AvailablePlaceholders = styled.div`
  padding: 2em;
`;

const SaveButton = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 8px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;

  &:hover {
    background-color: #45a049;
  }
`;

const SaveMessage = styled.div<{ isError: boolean }>`
  margin-top: 8px;
  color: ${(props) => (props.isError ? "#f44336" : "#4caf50")};
`;

const availablePlaceholders: Record<EmailId, string[]> = {
  confirm_email: [
    "email",
    "firstname",
    "lastname",
    "zipCode",
    "phoneNumber",
    "childFirstname",
    "childLastname",
  ],
  book_status_update: [
    "bookId",
    "status",
    "comment",
    "email",
    "childFirstname",
    "childLastname",
    "requestedAt",
  ],
  book_link: [
    "bookId",
    "downloadLink",
    "password",
    "email",
    "firstname",
    "lastName",
    "childFirstname",
    "childLastname",
    "phoneNumber",
    "postalCode",
    "requestedAt",
  ],
  deleted: [],
};

const mock: Record<string, string> = {
  firstname: "Daniel",
  lastname: "Girard",
  childFirstname: "Tom",
  childLastname: "Dupont",
  phoneNumber: "0102030405",
  zipCode: "70001",
  email: "bookin.lu@gmail.com",
  requestedAt: new Date().toLocaleString(),
  bookId: "9780123456789",
  status: "outdated",
  comment: "Ce livre est trop ancien et n'est pas pris en charge",
  downloadLink: encodeURI(
    "https://form.bookin.lu/7890123456789-bookin.lu@gmail.com"
  ),
};

const emails: Record<EmailId, { subject: string; content: string }> = {
  book_link: { subject: "", content: "" },
  book_status_update: { subject: "", content: "" },
  confirm_email: { subject: "", content: "" },
  deleted: { subject: "", content: "" },
};

const wrapperStyle = {
  border: "1px solid darkGrey",
  minHeight: "30em",
  backgroundColor: "white",
};

const editorStyle = {
  height: "auto",
};

const htmlToState = (html: string) =>
  EditorState.createWithContent(stateFromHTML(html));

const EmailEditor = () => {
  const [selectedEmail, setSelectedEmail] = useState<EmailId>("confirm_email");
  const [subject, setSubject] = useState(emails.confirm_email.subject);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    htmlToState(emails.confirm_email.content)
  );
  const [saveMessage, setSaveMessage] = useState("");

  useEffect(() => {
    setSubject(emails.confirm_email.subject);
    setEditorState(htmlToState(emails.confirm_email.content));
    getEmail(selectedEmail)
      .then((email) => {
        setSubject(email.subject);
        emails[selectedEmail] = email;
        setEditorState(htmlToState(email.content));
      })
      .catch(console.error);
  }, [selectedEmail]);

  const handleDropdownChange = useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >((e) => {
    setSelectedEmail(e.target.value as EmailId);
    setSaveMessage("");
  }, []);

  const handleSubjectChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setSaveMessage("");
    setSubject(e.target.value);
  }, []);

  const handleEditorChange = useCallback((newEditorState: EditorState) => {
    setSaveMessage("");
    setEditorState(newEditorState);
  }, []);

  const saveEmail = useCallback(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    setEmail(selectedEmail, subject, html)
      .then(() => setSaveMessage("Sauvegardé avec succès"))
      .catch((err: unknown) => {
        setSaveMessage(
          `Échec: ${
            err instanceof Error ? err.message : (err as object).toString()
          }`
        );
        console.error(err);
      });
  }, [editorState, selectedEmail, subject]);

  const html = useMemo(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    const htmlPreview = replaceEntities(html);
    return { __html: htmlPreview };
  }, [editorState]);

  return (
    <Container>
      <Dropdown value={selectedEmail} onChange={handleDropdownChange}>
        <option value="confirm_email">Email de confirmation</option>
        <option value="book_status_update">
          Changement de status (abandon)
        </option>
        <option value="book_link">Envoi du livre</option>
        <option value="deleted">Suppression d'un utilisateur</option>
      </Dropdown>
      <AvailablePlaceholders>
        <p>Vous pouvez utiliser les placeholders suivants dans cet email:</p>
        <ul>
          {availablePlaceholders[selectedEmail].map((holder) => (
            <li key={holder}>
              {holder}: Écrivez : {`{{${holder}}}`} pour un remplacement par :{" "}
              {mock[holder]}
            </li>
          ))}
        </ul>
      </AvailablePlaceholders>
      <TextInput
        type="text"
        value={subject}
        onChange={handleSubjectChange}
        placeholder="Email Subject"
      />
      <Editor
        wrapperStyle={wrapperStyle}
        editorStyle={editorStyle}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="editorWrapper"
        editorClassName="editor"
      />
      <h2>Prévisualisation:</h2>
      <Preview dangerouslySetInnerHTML={html} />
      <SaveButton onClick={saveEmail}>Save</SaveButton>
      {saveMessage && (
        <SaveMessage isError={saveMessage.startsWith("Échec")}>
          {saveMessage}
        </SaveMessage>
      )}
    </Container>
  );
};

export default EmailEditor;

function replaceEntities(html: string) {
  return html.replace(/\{\{(.*?)}}/g, (occ) => mock[occ.slice(2, -2)]);
}
