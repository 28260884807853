import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { createRequests, RequestCreation, RequestError } from "../api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2em;
  max-width: 100em;
  margin: auto;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  margin-bottom: 16px;
`;

const Button = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 8px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;

  &:hover {
    background-color: #45a049;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  background-color: #f1f1f1;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const Instructions = styled.div`
  margin-bottom: 16px;
`;

const Message = styled.div`
  color: #d32f2f;
  margin-top: 20px;
`;

const RequestImporter: React.FC = () => {
  const [textAreaContent, setTextAreaContent] = useState("");
  const [message, setMessage] = useState("");

  const handleTextAreaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaContent(e.target.value);
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    const originalRequests = parseTextAreaContent(textAreaContent);
    try {
      const response = await createRequests(originalRequests);
      // Handle response for successful requests
      if (response.success) {
        setMessage("All requests have been successfully processed.");
        setTextAreaContent(""); // Clear textarea if all requests are processed successfully
      }
    } catch (error) {
      console.error("Error processing requests: ", error);
      // error will contain the data from the rejected promise which includes invalidEmails, invalidBooks, and failedRequests
      const {
        invalidEmails = [],
        invalidBooks = [],
        failedRequests = [],
      } = error as RequestError;
      const errorMsg = [];
      if (invalidEmails && invalidEmails.length > 0) {
        errorMsg.push(
          `The following emails belong to unregistered users: ${invalidEmails.join(
            ", "
          )}`
        );
      }
      if (invalidBooks && invalidBooks.length > 0) {
        errorMsg.push(
          `The following books are not respecting the correct formatting: ${invalidBooks.join(
            ", "
          )}`
        );
      }
      if (failedRequests && failedRequests.length > 0) {
        errorMsg.push("Some requests failed with the following messages:");
        errorMsg.push(
          ...failedRequests.map(
            (req) => `- ${req.email} for book ${req.bookId}: ${req.error}`
          )
        );
      }
      setMessage(errorMsg.join("\n"));
      setTextAreaContent(
        reconstructTextAreaContent(originalRequests, error as RequestError)
      );
    }
  }, [textAreaContent]);

  return (
    <Container>
      <ContentBox>
        <h2>
          Copiez les requêtes à ajouter depuis un tableur. Attention à l'ordre
          des colonnes !
        </h2>
        <Instructions>
          Chaque information doit être séparée par une tabulation ou une
          virgule. Chaque utilisateur doit être sur une ligne différente.
          L'email de réception peut être une chaine vide, auquel cas il sera
          ignoré. Vous pouvez ajouter autant d'ID de livres que vous voulez.
          <br />
          email du compte | prénom de l'enfant | nom de l'enfant | email de
          réception | ID du livre 1 | ID du livre 2 | ...
        </Instructions>
        <TextArea value={textAreaContent} onChange={handleTextAreaChange} />
        <Button onClick={handleSubmit}>Envoyer</Button>
        {message && <Message>{message}</Message>}
      </ContentBox>
    </Container>
  );
};

export default RequestImporter;

function parseTextAreaContent(content: string): RequestCreation[] {
  const lines = content.trim().split("\n");
  const requests = [] as RequestCreation[];
  lines.forEach((line) => {
    const [email, childFirstname, childLastname, receptionEmail, ...bookIds] =
      line.split(/[\t,]/);
    bookIds.forEach((bookId) => {
      requests.push({
        email,
        bookId,
        childFirstname,
        childLastname,
        receptionEmail,
      });
    });
  });
  return requests;
}

function reconstructTextAreaContent(
  requests: RequestCreation[],
  result: RequestError
) {
  const failedBookSet = new Set(result.failedRequests.map((r) => r.bookId));
  const filteredRequests = requests.filter(
    (r) =>
      result.invalidEmails.includes(r.email) ||
      failedBookSet.has(r.bookId) ||
      result.invalidBooks.includes(r.bookId)
  );

  // Group the books by email
  const groupedRequests = {} as Record<string, string[]>;
  filteredRequests.forEach((req) => {
    const key = `${req.email}\t${req.childFirstname}\t${req.childLastname}\t${req.receptionEmail}`;
    if (!groupedRequests[key]) {
      groupedRequests[key] = [];
    }
    groupedRequests[key].push(req.bookId);
  });

  return Object.entries(groupedRequests)
    .map(([key, bookIds]) => `${key}\t${bookIds.join("\t")}`)
    .join("\n");
}
