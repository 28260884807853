import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { FormId, getForm, setForm } from "../api";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding: 2em;
  background-color: #f1f1f1;
`;

const Dropdown = styled.select`
  margin-bottom: 16px;
  padding: 8px;
  align-self: flex-start;
  background-color: white;
`;

const SaveButton = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 8px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;

  &:hover {
    background-color: #45a049;
  }
`;

const wrapperStyle = {
  border: "1px solid darkGrey",
  minHeight: "30em",
  backgroundColor: "white",
};

const editorStyle = {
  height: "auto",
};

const SaveMessage = styled.div<{ isError: boolean }>`
  margin-top: 8px;
  color: ${(props) => (props.isError ? "#f44336" : "#4caf50")};
`;

const forms: Record<FormId, string> = {
  confirmed: "",
  consent: "",
  consentQuestion: "",
  exposition: "",
  membershipRequest: "",
};

const htmlToState = (html: string) =>
  EditorState.createWithContent(stateFromHTML(html));

const ContentEditor = () => {
  const [selectedOption, setSelectedOption] = useState<FormId>("exposition");
  const [editorState, setEditorState] = useState<EditorState>(() =>
    htmlToState(forms.exposition)
  );
  const [saveMessage, setSaveMessage] = useState("");

  useEffect(() => {
    setEditorState(htmlToState(forms[selectedOption]));
    getForm(selectedOption)
      .then((form) => {
        setEditorState(htmlToState(form.content));
      })
      .catch(console.error);
  }, [selectedOption]);

  const handleDropdownChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedOption(e.target.value as FormId);
      setSaveMessage("");
    },
    []
  );

  const handleEditorStateChange = useCallback((newEditorState: EditorState) => {
    setEditorState(newEditorState);
    setSaveMessage("");
  }, []);

  const handleSave = useCallback(() => {
    const content = stateToHTML(editorState.getCurrentContent());
    setForm(selectedOption, content)
      .then(() => setSaveMessage("Sauvegardé avec succès"))
      .catch((err) => setSaveMessage(`Échec: ${(err as Error).message}`));
  }, [editorState, selectedOption]);

  return (
    <Container>
      <Dropdown value={selectedOption} onChange={handleDropdownChange}>
        <option value="exposition">Accueil</option>
        <option value="membershipRequest">Message aux non-membres</option>
        <option value="consent">Consentement</option>
        <option value="consentQuestion">Demande de consentement</option>
        <option value="confirmed">Message de confirmation</option>
      </Dropdown>
      <Editor
        wrapperStyle={wrapperStyle}
        editorStyle={editorStyle}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        wrapperClassName="editorWrapper"
        editorClassName="editor"
      />
      <SaveButton onClick={handleSave}>Save</SaveButton>
      {saveMessage && (
        <SaveMessage isError={saveMessage.startsWith("Échec")}>
          {saveMessage}
        </SaveMessage>
      )}
    </Container>
  );
};

export default ContentEditor;
