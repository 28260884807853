import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getForm } from "../api";
import { Button } from "./components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HtmlContent = styled.div`
  margin-bottom: 16px;
`;

const ConsentBox = styled.div`
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

const ConsentCheckbox = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

interface ConsentProps {
  onPrevious: () => void;
  onContinue: () => void;
}

const formData = {
  consent: "",
  consentQuestion: "",
};

const Consent: React.FC<ConsentProps> = ({ onPrevious, onContinue }) => {
  const [agreed, setAgreed] = useState(false);
  const [htmlContent, setHtmlContent] = useState(formData.consent);
  const [consentQuestion, setConsentQuestion] = useState(
    formData.consentQuestion
  );

  useEffect(() => {
    getForm("consent").then(({ content }) => {
      formData.consent = content;
      setHtmlContent(content);
    });
    getForm("consentQuestion").then(({ content }) => {
      formData.consentQuestion = consentQuestion;
      setConsentQuestion(content);
    });
  }, [consentQuestion]);

  const html = React.useMemo(() => ({ __html: htmlContent }), [htmlContent]);
  const question = React.useMemo(
    () => ({ __html: consentQuestion }),
    [consentQuestion]
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(e.target.checked);
  };

  const handleContinue = () => {
    if (agreed) {
      onContinue();
    }
  };

  return (
    <Container>
      <HtmlContent dangerouslySetInnerHTML={html} />
      <ConsentBox>
        <div dangerouslySetInnerHTML={question} />
        <ConsentCheckbox>
          <CheckboxInput
            type="checkbox"
            checked={agreed}
            onChange={handleCheckboxChange}
          />
          Oui, je suis conscient des conditions et je les accepte sans réserve.
        </ConsentCheckbox>
      </ConsentBox>
      <ButtonContainer>
        <Button color="negative" onClick={onPrevious}>
          Précédent
        </Button>
        <Button disabled={!agreed} onClick={handleContinue}>
          Continuer
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default Consent;
