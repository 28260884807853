import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { BOOK_PATTERN } from "../RequestForm/RequestForm";
import { Book, getBookStatus, submitBook } from "../api";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 0.5rem;
  align-self: stretch;
`;

const Select = styled.select`
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid gray;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  background-color: #0077cc;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 2em;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

function BookForm({
  onUpdate,
  selectedBook,
}: {
  onUpdate: () => void;
  selectedBook: string;
}) {
  const [bookId, setBookId] = useState<Book["bookId"]>(selectedBook);
  const [status, setStatus] = useState<Book["status"]>("unknown");
  const [comment, setComment] = useState<Book["comment"]>("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  // Update the bookId when the prop change
  useEffect(() => {
    selectedBook && setBookId(String(selectedBook));
  }, [selectedBook]);

  // When the bookId value changes, load the status and comment
  useEffect(() => {
    const regex = new RegExp(BOOK_PATTERN);
    if (regex.test(bookId)) {
      getBookStatus(bookId)
        .then(({ status, comment }) => {
          setStatus(status);
          setComment(comment);
          setSuccess(false);
          setError("");
        })
        .catch((err) => {
          setStatus("unknown");
          setComment("");
          setSuccess(false);
          setError("");
          console.error(err);
        });
    }
  }, [bookId]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      submitBook({ bookId, status, comment: comment?.trim() })
        .then(() => {
          setBookId("");
          setComment("");
          setStatus("unknown");
          setSuccess(true);
          setError("");
        })
        .catch((error) => {
          setSuccess(false);
          setError(error.message);
        })
        .finally(onUpdate);
    },
    [bookId, comment, onUpdate, status]
  );

  const handleBookIdChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => setBookId(event.target.value.replace(/\s/g, "")),
      []
    );

  const handleStatusChange: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback((event) => setStatus(event.target.value as Book["status"]), []);

  const handleCommentChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => setComment(event.target.value), []);

  return (
    <Form onSubmit={handleSubmit}>
      <h2>Mettre à jour un livre non disponible</h2>
      {success && <p color="green">Book updated successfully</p>}
      {error && <p color="red">Error: {error}</p>}
      <InputGroup>
        <Label>Book ID:</Label>
        <Input
          type="text"
          required
          pattern={BOOK_PATTERN}
          value={bookId}
          onChange={handleBookIdChange}
        />
      </InputGroup>
      <InputGroup>
        <Label>Status:</Label>
        <Select
          name="status"
          value={status || "pending"}
          onChange={handleStatusChange}
        >
          <option value="unknown" disabled>
            -
          </option>
          <option value="available">Dispo</option>
          <option value="pending">En attente</option>
          <option value="unavailable">Indisponible</option>
          <option value="requested">Demandé à la BNF</option>
          <option value="outdated">Trop ancien</option>
        </Select>
      </InputGroup>
      <InputGroup>
        <Label>Comment:</Label>
        <Input
          type="text"
          list="motives"
          value={comment}
          onChange={handleCommentChange}
        />
        <datalist id="motives">
          <option>
            Ce livre est une Bande Dessinée dont les textes ne sont pas tous
            sélectionnables. Par conséquent, il ne sera pas possible que les
            dialogues soient lus par une synthèse vocale ou traités par une
            modification d'affichage.
          </option>
          <option>
            Ce code correspond à un ouvrage numérique qui n'entre pas de la
            cadre de la loi exception handicap au droit d'auteur ; en effet
            seuls les livres papier (que nous transformons et adaptons en PDF)
            peuvent vous être envoyés.
          </option>
          <option>
            Ce code est erroné. Veuillez remplir un nouveau formulaire avec un
            code valide. Vous pouvez chercher un code correct sur le site de
            l'excellent libraire{" "}
            <a href="https://www.decitre.fr">www.decitre.fr</a>
          </option>
          <option>
            L’éditeur de cet ouvrage est un éditeur non-coopératif qui, malgré
            la loi que l’y oblige, refuse de livrer ses fichiers. Par
            conséquent, nous ne pouvons pas vous fournir cet ouvrage.
          </option>
          <option>
            Cet ouvrage ne peut vous être envoyé et nous en sommes désolés ; en
            effet l'éditeur a fourni un document original que nous ne pouvons
            pas adapter pour des raisons techniques.
          </option>
          <option>
            Nous ne pouvons pas vous fournir cette référence car il s'agit d'un
            document publié par un éditeur étranger qui n'est pas soumis à la
            loi française concernant l’exception handicap au droit d’auteur.
          </option>
          <option>
            La Bibliothèque Nationale de France qui est l'intermédiaire entre
            les éditeurs et Bookin nous informe que ce document n'appartient pas
            au fonds de l'éditeur. Par conséquent, nous ne pouvons pas l'obtenir
            et vous le fournir.
          </option>
          <option>
            L'éditeur nous a fait savoir qu'il n'a pas conservé les archives
            numériques de cet ouvrage . Par conséquent, nous ne pouvons pas vous
            le fournir
          </option>
          <option>
            L'éditeur a annulé l'édition de cet ouvrage. Par conséquent, nous ne
            pouvons pas vous le fournir.
          </option>
        </datalist>
      </InputGroup>
      <Button type="submit">Mettre à jour le livre</Button>
    </Form>
  );
}

export default BookForm;
