import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Confirmation from "./Confirmation";
import EmailChecker from "./EmailChecker";
import RequestForm from "./RequestForm";
import useBackKey from "../utils/useBackKey";
import Consent from "./Consent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  flex-direction: column;
  align-items: stretch;
  max-width: 40em;
  margin: auto;
  padding: 4em;
  flex: 1 1 auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 0;
  background: var(--linear, linear-gradient(136deg, #44276e 0%, #733c8f 100%));
  border-bottom: 1px solid #e0e0e0;
`;

const HeaderLogo = styled.img`
  max-width: 13em;
  color: "#ffffff";
`;

const Footer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 1.5em 0;
  margin-top: 3em;
`;

const FooterText = styled.span`
  margin-left: 0.8em;
  font-size: 0.9em;
`;

const FooterLogo = styled.img`
  max-height: 1em;
  color: "#fff";
  margin: 0 0.3em;
  transition: max-height 0.2s;
  &:hover {
    max-height: 1.5em;
  }
`;

const pageCSS = { __html: `li { margin-bottom: 0.5em; }` };

const steps = ["memberCheck", "consent", "form", "confirmed"];
type Step = (typeof steps)[number];
const EMAIL_KEY = "email";

function Form() {
  const paramEmail = new URLSearchParams(window.location.search).get("email");
  const [step, setStep] = useState<Step>("memberCheck");
  const [email, setEmail] = useState(
    paramEmail || localStorage.getItem(EMAIL_KEY)
  );
  const goBack = React.useCallback(() => {
    setStep((step) => {
      const index = steps.indexOf(step);
      return steps[Math.max(0, index - 1)];
    });
  }, []);

  const nextStep = useCallback(() => {
    setStep((step) => {
      const index = steps.indexOf(step);
      return steps[(index + 1) % steps.length];
    });
  }, []);

  const onEmailChecked = React.useCallback(
    (email: string) => {
      setEmail(email);
      localStorage.setItem(EMAIL_KEY, email);
      nextStep();
    },
    [nextStep]
  );

  useBackKey(goBack);

  const body = useMemo(() => {
    if (step === "consent")
      return <Consent onContinue={nextStep} onPrevious={goBack} />;
    else if (step === "form" && email)
      return (
        <RequestForm email={email} onContinue={nextStep} goBack={goBack} />
      );
    else if (step === "confirmed")
      return <Confirmation email={email || ""} onNewForm={nextStep} />;
    else
      return (
        <EmailChecker email={email || undefined} onContinue={onEmailChecked} />
      );
  }, [email, goBack, nextStep, onEmailChecked, step]);

  return (
    <Container>
      <style type="text/css" dangerouslySetInnerHTML={pageCSS} />
      <Header>
        <HeaderLogo src="assets/bookin.svg" alt="Bookin Logo" />
      </Header>
      <Wrapper>{body}</Wrapper>
      <Footer>
        <FooterText>Système automatisé développé et offert par</FooterText>
        <a href="https://cantoo.fr" target="_blank">
          <FooterLogo src="assets/cantoo.svg" alt="Cantoo Logo" />
        </a>
      </Footer>
    </Container>
  );
}

export default Form;
