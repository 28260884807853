import styled from "styled-components";
import { Button } from "./components";

const Message = styled.h2`
  margin-bottom: 24px;
`;

type ConfirmationProps = {
  onNewForm: () => void;
  email: string;
};

function Confirmation({ onNewForm, email }: ConfirmationProps) {
  return (
    <>
      <Message>
        Le formulaire a été envoyé avec succès&nbsp;! Vous recevrez une
        confirmation par email sur {email}.
      </Message>
      <Button onClick={onNewForm}>Remplir un nouveau formulaire</Button>
    </>
  );
}

export default Confirmation;
