import React, { useState, useEffect, useCallback, FormEvent } from "react";
import styled from "styled-components";
import {
  getAllBooks,
  getAllRequests,
  Book,
  ExtendedRequest,
  uploadBook,
  getFreeSpace,
  updateConfiguration,
  getConfiguration,
} from "../api";
import BookForm from "./BookForm";
import { colorsPerStatus } from "../RequestForm/RequestForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const CopyLink = styled.span`
  font-weight: bold;
  color: blue;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  margin-bottom: 32px;
  display: block;
  max-height: 30em;
  overflow: auto;
`;

const TableHeader = styled.th`
  background-color: #e8e8ff;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const TableRow = styled.tr`
  background-color: #fffff1;
  &:nth-child(even) {
    background-color: #f1f1ff;
  }
`;

const TableHead = styled.tbody`
  position: sticky;
  top: 0;
`;

const TableCell = styled.td<{ status?: Book["status"] }>`
  ${(props) =>
    props.status ? `background-color: ${colorsPerStatus[props.status]};` : ""}
  border: 1px solid #ddd;
  padding: 8px;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const FilesLabel = styled.label`
  cursor: pointer;
  padding: 1em 1em 0;
`;

const FilesInput = styled.input`
  cursor: pointer;
  padding: 0 1em 1em;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  background-color: #f1f1f1;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const PickFolderButton = styled(ContentBox)`
  background-color: white;
  transition: background-color 0.3s;
  padding: 0;

  &:hover {
    background-color: #0056b3;
  }
`;

const ServerInfo = styled.div`
  padding: 1em;
  font-size: 1.2em;
  background-color: #0056b3;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Input = styled.input`
  padding: 0.5em;
  margin-right: 1em;
`;

const Button = styled.button`
  padding: 0.5em 1em;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

// Fonction pour trouver la date la plus ancienne dans une liste de Request
function oldestRequestDate(requests: ExtendedRequest[]): Date {
  return requests.reduce((oldest, current) => {
    return current.requestedAt < oldest ? current.requestedAt : oldest;
  }, new Date());
}

function Dashboard() {
  const [requests, setRequests] = useState<ExtendedRequest[]>([]);
  const [pendingBooks, setPendingBooks] = useState<Book[]>([]);
  const [selectedBook, setSelectedBook] = useState("");
  const [uploadsFailed, setUploadFailed] = useState<string[]>([]);
  const [spaceLeft, setSpaceLeft] = useState(0);
  const [cleanupTime, setCleanupTime] = useState("");
  const [filesDuration, setFilesDuration] = useState("");

  const updateDashboard = React.useCallback(() => {
    // Fetch the requests and pending books from the server once per minute
    getAllBooks()
      .then((books) => setPendingBooks(books))
      .catch(console.error);
    getAllRequests()
      .then((requests) =>
        setRequests(
          requests.sort((a, b) =>
            a.requestedAt < b.requestedAt
              ? 1
              : a.requestedAt > b.requestedAt
              ? -1
              : 0
          )
        )
      )
      .catch(console.error);
    getFreeSpace()
      .then(({ space, cleanupDate }) => {
        setSpaceLeft(Math.round(parseInt(space) / 1000 / 1000));
        setCleanupTime(new Date(cleanupDate).toLocaleTimeString("fr"));
      })
      .catch(console.error);

    getConfiguration("FILES_DURATION")
      .then((config) => {
        setFilesDuration(config.value);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    updateDashboard();
    const interval = setInterval(updateDashboard, 1000 * 60);

    // Hack to fix React not supporting directory option
    document.getElementById("files")?.setAttribute("webkitdirectory", "");
    // Clear the interval on unmount
    return () => clearInterval(interval);
  }, [updateDashboard]);

  const handleFilesFolder: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      async (event) => {
        setUploadFailed([]);
        const files = event.target.files;
        if (files) {
          [...files]
            .filter((file) => file.name.toLowerCase().endsWith(".pdf"))
            .map(
              (file) =>
                [
                  file,
                  requests.filter(
                    (request) =>
                      file.name.toLowerCase() === request.bookId + ".pdf"
                  ),
                ] as [File, ExtendedRequest[]]
            )
            .filter(([, requesters]) => requesters.length)
            .sort((a, b) => {
              const oldestA = oldestRequestDate(a[1]);
              const oldestB = oldestRequestDate(b[1]);
              return oldestA < oldestB ? -1 : oldestA > oldestB ? 1 : 0;
            })
            .map(([file, requesters]) => async () => {
              const emails = requesters.map((request) => request.email);
              return uploadBook(file, emails).catch((error) => {
                console.error(error);
                setUploadFailed((failed) => [...failed, file.name]);
              });
            })
            .reduce<Promise<void>>(async (promise, promiseFunction) => {
              await promise;
              return promiseFunction();
            }, Promise.resolve())
            .finally(updateDashboard);
        }
      },
      [requests, updateDashboard]
    );

  const handleUpdateFilesDuration = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget); // Use currentTarget instead of target
    const duration = formData.get("filesDuration") as string; // Retrieves the value and ensures it's treated as a string

    const newDuration = "" + Math.min(31, Math.max(1, parseInt(duration) || 1));
    updateConfiguration("FILES_DURATION", newDuration)
      .then(() => {
        alert("Durée mise à jour avec succès");
        setFilesDuration(newDuration);
      })
      .catch((error) => {
        alert("Échec de la mise à jour : " + error.message);
      });
  };

  const requestRows = React.useMemo(() => {
    return requests.map((request) => {
      const receptionEmail =
        request.email !== request.receptionEmail ? request.receptionEmail : "";
      return (
        <TableRow key={`${request.email}-${request.bookId}`}>
          <TableCell>
            <a href={`mailto:${request.email}̀`}>{request.email}</a>
          </TableCell>
          <TableCell>
            <a href={`tel:${request.phoneNumber}̀`}>{request.phoneNumber}</a>
          </TableCell>
          <TableCell>{request.firstname}</TableCell>
          <TableCell>{request.lastname}</TableCell>
          <TableCell
            status={request.status}
            onClick={() => setSelectedBook(request.bookId)}
          >
            <CopyLink>{request.bookId}</CopyLink>
          </TableCell>
          <TableCell>{request.childFirstname}</TableCell>
          <TableCell>{request.childLastname}</TableCell>
          <TableCell>
            <a href={`mailto:${receptionEmail}̀`}>{receptionEmail}</a>
          </TableCell>
          <TableCell>
            {request.requestedAt &&
              new Date(request.requestedAt).toLocaleString()}
          </TableCell>
        </TableRow>
      );
    });
  }, [requests]);

  const bookRows = React.useMemo(() => {
    return pendingBooks
      .sort(
        (a, b) =>
          new Date(b.requestedAt || 0).getTime() -
          new Date(a.requestedAt || 0).getTime()
      )
      .map((book) => (
        <TableRow key={book.bookId}>
          <TableCell onClick={() => setSelectedBook(book.bookId)}>
            <CopyLink>{book.bookId}</CopyLink>
          </TableCell>
          <TableCell>{book.status}</TableCell>
          <TableCell>{book.comment}</TableCell>
          <TableCell>
            {book.requestedAt && new Date(book.requestedAt).toLocaleString()}
          </TableCell>
        </TableRow>
      ));
  }, [pendingBooks]);

  return (
    <Container>
      <ServerInfo>
        Espace disque restant: {spaceLeft} Go. Prochain nettoyage prévu à{" "}
        {cleanupTime}
        <form onSubmit={handleUpdateFilesDuration}>
          <label>
            Durée de conservation (jours):
            <Input
              type="number"
              name="filesDuration"
              defaultValue={filesDuration}
            />
          </label>
          <Button type="submit">Modifier</Button>
        </form>
      </ServerInfo>
      <ContentBox>
        <h2>Requêtes en attentes: {requestRows.length}</h2>
        <Table>
          <TableHead>
            <tr>
              <TableHeader>Email</TableHeader>
              <TableHeader>Téléphone</TableHeader>
              <TableHeader>Prénom</TableHeader>
              <TableHeader>Nom</TableHeader>
              <TableHeader>Book ID</TableHeader>
              <TableHeader>Prénom enfant</TableHeader>
              <TableHeader>Nom enfant</TableHeader>
              <TableHeader>Email pour l'envoi</TableHeader>
              <TableHeader>Demandé le</TableHeader>
            </tr>
          </TableHead>
          <tbody>{requestRows}</tbody>
        </Table>
        <PickFolderButton>
          <FilesLabel htmlFor="files">
            Choisi le dossier contenant les livres pdf pour traiter toutes les
            requêtes
          </FilesLabel>
          <FilesInput
            id="files"
            type="file"
            {...{ webkitdirectory: "", directory: "" }}
            onChange={handleFilesFolder}
          />
        </PickFolderButton>
        {uploadsFailed.map((failed) => (
          <ErrorMessage>{failed} failed to upload</ErrorMessage>
        ))}
      </ContentBox>
      <ContentBox>
        <h2>Livres à rechercher: : {bookRows.length}</h2>
        <Table>
          <TableHead>
            <tr>
              <TableHeader>Book ID</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Comment</TableHeader>
              <TableHeader>Demandé le</TableHeader>
            </tr>
          </TableHead>
          <tbody>{bookRows}</tbody>
        </Table>

        <BookForm onUpdate={updateDashboard} selectedBook={selectedBook} />
      </ContentBox>
    </Container>
  );
}

export default Dashboard;
