import React from "react";

import BackHandler from "./BackHandler";

/** This helper will call onBackPress when we press the hardware back button */
const useBackKey = (onBackPress?: () => void) => {
  React.useEffect(() => {
    if (!onBackPress) return;
    const onBack = () => {
      onBackPress();
      return true;
    };
    BackHandler.addEventListener("hardwareBackPress", onBack);
    return () => BackHandler.removeEventListener("hardwareBackPress", onBack);
  }, [onBackPress]);
};

export default useBackKey;
